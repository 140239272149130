import React from "react";
import ArrowButton from "../../elements/Buttons/ArrowButton/ArrowButton";
import headerImg from "../../../assets/img/DerekHeadshot.webp";
import plaidHeaderImg from "../../../assets/img/DerekAnimatedHeadshot.webp";
import "animate.css";

function ProfileCard({ text, onClick, plaid }) {
  const isMobile = true;
  const renderDescription = () => {
    if (plaid) {
      return (
        <div>
          <p>What makes me a good fit for plaid?</p>
          <ul id="accomplishments">
            <li>
              I am genuinely interested in working on financial technology and
              resonate with the company&apos;s mission to revolutionize
              financial interactions.
            </li>
            <li>Track record of understanding and addressing customer needs</li>
            <li>
              Ability to work across multiple teams and tackle ambitious
              problems
            </li>
            <li>Successful mentorship and fostering of team growth</li>
          </ul>
        </div>
      );
    } else {
      return (
        <p>
          I’m a dynamic, motivated, results-oriented Developer who enjoys
          developing innovative solutions that drive technological advancement.
          Throughout my career, I’ve acquired and honed outstanding skills in
          system design, project management, testing, validation, research, and
          Agile methodologies. Further, I maintain a proven track record of
          completing projects on-time and under budget while exceeding stringent
          expectations.
        </p>
      );
    }
  };
  return (
    <div className="row">
      <div className="pane-left">
        <h1>Derek DeLizo</h1>
        <h2>They/Them</h2>
        <div>
          <h3>
            {`I'm `}{" "}
            <span
              className="txt-rotate"
              data-rotate='[ "a Fullstack Developer", "a System Engineer", "an Electrical Engineer" ]'
            >
              <span className="wrap">{text}</span>
            </span>
          </h3>
          {renderDescription()}
          <div className="arrow-buttons button-top">
            <ArrowButton
              arrowLocation="right"
              fillColor="white"
              onClick={onClick}
              text={plaid ? " Let me explain why " : " My Story "}
            />
            <ArrowButton
              classesToAdd="button-space"
              arrowLocation="right"
              fillColor="white"
              url="/resume"
              text=" Resume "
            />
          </div>
        </div>
      </div>
      <div className="pane-right">
        <img rel="preload" src={plaid ? plaidHeaderImg : headerImg} alt="Header Img" />
      </div>
    </div>
  );
}

export default ProfileCard;
