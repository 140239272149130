import React, { useState, useEffect, useContext } from "react";
import { WindowContext } from "../../../App";
import ArrowButton from "../../elements/Buttons/ArrowButton/ArrowButton";
import headerImg from "../../../assets/img/DerekAnimatedHeadshot.webp";
import "animate.css";

function AboutMe({ onClick, text, plaid }) {
  const [isMobile, setIsMobile] = useState();
  const dimensions = useContext(WindowContext);
  const displayText = () => {
    if (plaid) {
      if (isMobile) {
        return (
          <p>
            Based on my background in Software Engineering, I believe I would be
            a strong fit for Plaid. During my time at the University of
            Washington Bothell, I gained a solid foundation in engineering and
            had the opportunity to contribute to several impactful research
            projects. Some highlights include developing a user interface in
            MATLAB to decode the language of crows for the biology department
            and creating a cloud-based underwater acoustic recorder to monitor
            noise pollution in Lake Washington. Alongside my technical skills, I
            have a passion for fintech solutions, skateboarding, snowboarding,
            and undertaking various personal projects. I am excited to bring my
            expertise, creativity, and dedication to Plaid&apos;s mission while
            enjoying time with my beloved dog during breaks.
          </p>
        );
      }
      return (
        <p>
          Based on my background in Software Engineering and my diverse
          experiences, I believe I would be a strong fit for Plaid. During my
          time at the University of Washington Bothell Campus, I not only gained
          a solid foundation in engineering but also had the opportunity to work
          as a Game Designer on Ghostlight Manor and contribute to several
          impactful research projects. Some highlights include developing a user
          interface in MATLAB to decode the language of crows for the biology
          department, creating a cloud-based underwater acoustic recorder to
          monitor noise pollution in Lake Washington, and building an Augmented
          Reality assembly manual for the E-nable Raptor Reloaded 3D printable
          prosthetic hand using Unity. Alongside my technical skills, I have a
          passion for fintech solutions, skateboarding, snowboarding, and
          undertaking various personal projects. I am excited to bring my
          expertise, creativity, and dedication to Plaid&apos;s mission while
          enjoying time with my beloved dog during breaks.
        </p>
      );
    } else {
      return (
        <p>
          I studied Electrical Engineering at the University of Washington
          Bothell Campus. While there, I worked as a Game Designer on Ghostlight
          Manor and participated in a handful of undergraduate research
          projects. A few of my favorites would be building a user interface in
          MATLAB to assist the biology department in decoding the language of
          crows, creating a cloud-based underwater acoustic recorder to monitor
          noise pollution in Lake Washington, and using unity to make an
          Augmented Reality assembly manual that showed how to assemble the
          E-nable Raptor Reloaded 3D printable prosthetic hand. When I am not
          working, I like to play video games, skateboard or snowboard
          (depending on the season), work on various projects, and spend time
          with my dog. Speaking of my dog, I need to take her for a walk!
        </p>
      );
    }
  };

	useEffect(() => {
		if (dimensions.width < 992) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [dimensions]);

  return (
    <div className="row">
      <div className={plaid ? "about-me" : "about-me pane-left"}>
        <ArrowButton
          classesToAdd="button-bottom"
          arrowLocation="left"
          fillColor="white"
          onClick={onClick}
          text=" Back "
        />
        <article>
          <h1 className="title">
            {plaid ? "Let me explain why..." : "About Me"}
          </h1>
          {displayText()}
        </article>
      </div>
      {!plaid && (
        <div className="pane-right">
          <img rel="preload" src={headerImg} alt="Header Img" />
        </div>
      )}
    </div>
  );
}

export default AboutMe;
